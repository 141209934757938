@import '../abstract/variables';

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

html {
  // font-size: 62.5%;
}

body {
  box-sizing: border-box;
  font-family: $max-rounded;
  font-size: 14px;
  color: #1e2d4f;
}
