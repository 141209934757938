@import "./layout/layout";
@import "./base/animations";
@import "./base/base";
@import "./base/typography";
@import "./base/utilities";
@import "./abstract/functions";
@import "./abstract/mixins";
@import "./abstract/variables";

//Remove the outline from the slick items
.slick-slide {
  outline: none !important;
}

.slick-dots {
  top: 500px;
  display: flex !important;
  max-width: 80%;
  align-items: center;
  border: solid 1px #f0eded;
  border-radius: 5px;
  justify-content: center;
  left: 110px;
}

.slick-dots li button:before {
  content: "" !important;
}

.slick-dots li {
  display: flex !important;
  height: 1px !important;
  flex: 1;
  margin: 0px !important;
}

.slick-dots .slick-active {
  border: solid 1px #7632ff;
  border-radius: 5px;
}

.MuiPaper-rounded {
  border-radius: 10px !important;
}

.MuiSnackbarContent-message {
  font-size: 16px;
  line-height: 1.5;
}

.MuiMenuItem-root,
.MuiAutocomplete-popper > li {
  font-size: 16px !important;
  font-family: $max-rounded;
}

.MuiSelect-icon {
  top: calc(50% - 7px) !important;
}

.bg-white {
  background: white;
  border-radius: 10px;
}

.pointer {
  cursor: pointer;
}

.noWrap {
  white-space: nowrap;
}

.outlineNone {
  outline: none;
}

.errorlabel {
  color: #f44336;
  font-size: 12px;
  font-family: $max-rounded;
  margin-left: 14px;
  letter-spacing: 0.03333em;
  margin-top: 3px;
  line-height: 1.66;
}

.zoom-link {
  color: #7632ff;
  word-break: break-all;
  text-decoration: none;
  button {
    color: #7632ff;
    font-size: 14px;
    text-transform: capitalize;
  }
}

//Media query for mobile devices
@media screen and (max-width: 600px) {
  .MuiPaper-rounded {
    border-radius: 0px !important;
  }
}

a {
  text-decoration: none;
}
