@font-face {
  font-family: "max-rounded";
  src: url("../../assets/fonts/Maax-Rounded.ttf") format("truetype");
}

@font-face {
  font-family: "max-rounded-bold";
  src: url("../../assets/fonts/Maax-Rounded-Bold.ttf") format("opentype");
}

@font-face {
  font-family: "max-rounded-bold-italic";
  src: url("../../assets/fonts/Maax-Rounded-BoldItalic.ttf") format("opentype");
}

@font-face {
  font-family: "max-rounded-italic";
  src: url("../../assets/fonts/Maax-Rounded-Italic.ttf") format("opentype");
}

@font-face {
  font-family: "max-rounded-medium";
  src: url("../../assets/fonts/Maax-Rounded-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "max-rounded-medium-italic";
  src: url("../../assets/fonts/Maax-Rounded-MediumItalic.ttf")
    format("opentype");
}

@font-face {
  font-family: "montserrat";
  src: url("../../assets/fonts/Montserrat/Montserrat-Regular.ttf")
    format("opentype");
}

@font-face {
  font-family: "montserrat-medium";
  src: url("../../assets/fonts/Montserrat/Montserrat-Medium.ttf")
    format("opentype");
}

@font-face {
  font-family: "montserrat-semi-bold";
  src: url("../../assets/fonts/Montserrat/Montserrat-SemiBold.ttf")
    format("opentype");
}

@font-face {
  font-family: "montserrat-bold";
  src: url("../../assets/fonts/Montserrat/Montserrat-Bold.ttf")
    format("opentype");
}

@font-face {
  font-family: "montserrat-light";
  src: url("../../assets/fonts/Montserrat/Montserrat-Light.ttf")
    format("opentype");
}
