//Font - MaxRounded
$max-rounded: max-rounded, sans-serif;
$max-rounded-bold: max-rounded-bold, sans-serif;
$max-rounded-bold-italic: max-rounded-bold-italic, sans-serif;
$max-rounded-italic: max-rounded-italic, sans-serif;
$max-rounded-medium: max-rounded-medium, san-serif;
$max-rounded-medium-italic: max-rounded-medium-italic, sans-serif;

//Font - Montserrat
$montserrat: montserrat, sans-serif;
$montserrat-medium: montserrat-medium, sans-serif;
$montserrat-semi-bold: montserrat-semi-bold, sans-serif;
$montserrat-bold: montserrat-bold, sans-serif;
$montserrat-light: montserrat-light, sans-serif;

//fonts
.max-rounded {
  font-family: $max-rounded;
}
.max-rounded-bold {
  font-family: $max-rounded-bold;
}
.max-rounded-bold-italic {
  font-family: $max-rounded-bold-italic;
}
.max-rounded-italic {
  font-family: $max-rounded-italic;
}
.max-rounded-medium {
  font-family: $max-rounded-medium;
}
.max-rounded-medium-italic {
  font-family: $max-rounded-medium-italic;
}

//Breakpoints
$xs: '(max-width: 575px)';
$sm-only: '(min-width: 576px) and (max-width: 768px)';
$md-only: '(min-width: 769px) and (max-width: 1024px)';
$lg-only: '(min-width: 1025px) and (max-width: 1199px)';
$xl: '(min-width: 1200px)';

$sm-up: '(min-width: 576px)';
$md-up: '(min-width: 769px)';
$lg-up: '(min-width: 1025px)';
$xl-up: '(min-width: 1200px)';
$xxl-up: '(min-width: 1440px)';

$xs-down: '(max-width: 575px)';
$sm-down: '(max-width: 768px)';
$md-down: '(max-width: 1024px)';
$lg-down: '(max-width: 1199px)';
$xl-down: '(max-width: 1200px)';
$xxl-down: '(max-width: 1439px)';
