.flex {
  display: flex;
}

.row {
  flex-direction: row;
}

.column {
  flex-direction: column;
}

.wrap {
  flex-flow: wrap;
}

.absolute {
  position: absolute;
}

.center-center {
  align-items: center;
  justify-content: center;
}

.start-center {
  align-items: center;
  justify-content: flex-start;
}

.between-center {
  align-items: center;
  justify-content: space-between;
}

.align-center {
  align-items: center;
}

.start-end {
  align-items: flex-start;
  justify-content: flex-end;
}

.end-end {
  align-items: flex-end;
  justify-content: flex-end;
}

.center {
  text-align: center;
}

.flex-1 {
  flex: 1;
}
